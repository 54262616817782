@import "../../scss/variables";

.sidebar-toc .sidebar-toc__title {
    font-size: 1.8rem;
}

.awsui .sidebar-toc__list__item {
    border-top: 1px solid #d5dbdb;
    padding: 0;

    a {
        padding: 0 2rem;
        display: block;
        width: 100%;
        color: $awsuiBlack;

        &:focus,
        &:active,
        &:hover {
            color: $awsuiOrange500;
        }
    }
}
