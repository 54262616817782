.lab-content {
    width: 100%;

    &.is-loading .lab-content__inner {
        height: 100%;
    }
}

.lab-content__inner {
    overflow: initial;
}
