.dev-resources {
    padding: 20px;

    code {
        padding: 3px 5px;
        background-color: rgb(235, 235, 235);
        border-radius: 5px;
    }
}

.dev-resources__link {
    text-decoration: underline;
    color: blue;
}
