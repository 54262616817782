* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

button {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
}

.awsui ol,
.awsui ul {
    padding: 0;
}

li {
    list-style-type: none;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

// ---------------------------------
// Helpers
// ---------------------------------
.is-visually-hidden {
    border: 0 !important;
    clip: rect(1px 1px 1px 1px) !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    left: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 1px !important;
}
