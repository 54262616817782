@import "../../../scss/variables";

.labs-markdown-content .labs-code-block pre {
    user-select: text;
}
.labs-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.labs-vertical-align-top {
    vertical-align: top !important;
}

.labs-box {
    display: block;
    border-radius: 4px;
    border: 1px #ddd solid;
    background-color: $labsGrey300;

    .labs-box-inner {
        border-radius: 4px;
        position: relative;
        padding: 14px 18px;
    }
}

.labs-code-block {
    position: relative;
    margin-bottom: 0.8em;

    .labs-box-inner {
        padding: 0 !important;

        pre {
            padding: 14px 18px;
            margin: 0;
            border-radius: 4px;
        }
    }
}
