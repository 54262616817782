@import "../../../scss/variables";

// Pulled the following markup from AWS Session UI
// Tried to delete some to avoid changing AWSUI too much
// Still doesn't look that great when rendered and could probably use some tidy up

.awsui .labs-markdown-content {
    padding: 2rem 0;

    ol,
    ul {
        padding-left: 2rem;
    }

    ol li > p,
    ul li > p {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: inherit;
    }

    ol.top-level {
        margin: 1em 0;

        li:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    img {
        margin: 1.2rem auto;
        max-width: 80%;
    }

    input.inline-code {
        font-family: $awsuiMonospace;
        padding: 0.2rem 0.4rem;
        color: $awsuiRed600;
        background-color: $awsuiGrey100;
        border: 1px solid $labsGrey300;
        white-space: nowrap;
        cursor: text;
    }

    h1 {
        margin-bottom: 7rem;
    }

    h2 {
        margin-top: 5.5rem;
        margin-bottom: 2rem;
    }

    h3 {
        margin-top: 3.5rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    hr {
        margin: 1.5rem 0;
    }

    p {
        margin: 1.5rem 0;
    }

    p > a,
    p > span,
    p > i,
    li > span,
    li > i {
        margin: 0 5px;
    }

    kbd {
        font-family: Lato, Helvetica Neue, Helvetica, sans-serif;
        background-color: $awsuiWhite;
        border: 1px solid rgba(63, 63, 63, 0.25);
        border-radius: 3px;
        // .box-shadow(0 1px 0 rgba(63, 63, 63, 0.25));
        color: $awsuiGrey900;
        display: inline-block;
        font-size: 0.8em;
        margin: 0 0.1em;
        padding: 0.1em 0.6em;
        white-space: nowrap;
    }

    table {
        margin: 1.5rem 0;
        border-collapse: collapse;

        &,
        th,
        td {
            border: 1px solid $awsuiAwsSquidInk;
        }

        th,
        td {
            padding: 0.8rem;
        }
    }

    strong {
        margin: 0 5px;
    }
}
