@import "./variables.scss";

// ---------------------------------
// Responsive Breakpoints
// ---------------------------------
$breakpoints: (
    "sm": (
        min-width: $break-sm,
    ),
    "md": (
        min-width: $break-md,
    ),
    "lg": (
        min-width: $break-lg,
    ),
    "xl": (
        min-width: $break-xl,
    ),
);

@mixin breakpoint($name) {
    @media #{inspect(map-get($breakpoints, $name))} {
        @content;
    }
}
