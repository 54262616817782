
// Responsive breakpoints
// -------------------------------------------------------------
    $break-sm: 576px !default;
    $break-md: 768px !default;
    $break-lg: 961px !default;
    $break-xl: 1200px !default;

// Color
// -------------------------------------------------------------
    $awsuiOrange500: var(--awsui-color-orange-500) !default;
    $awsuiWhite: var(--awsui-color-white) !default;
    $awsuiBlack: var(--awsui-color-black) !default;

    // Secondary colors
    $awsuiRed600: var(--awsui-color-red-600) !default;
    $awsuiGreen600: var(--awsui-color-green-600) !default;

    $awsuiGrey100: var(--awsui-color-grey-100) !default;
    $awsuiGrey200: var(--awsui-color-grey-200) !default;
    $awsuiGrey300: var(--awsui-color-grey-300) !default;
    $awsuiGrey900: var(--awsui-color-grey-900) !default;
    $labsGrey300: #E5E9E9 !default;
    $labsGrey400: #7C868C !default;
    $labsGrey450: #545B64 !default;
    $labsGrey500: #879196 !default;
    $labsGrey550: #424951 !default;

    // Tertiary colors
    $labsDropShadow: #121318 !default;

    // Brand colors
    $awsuiAwsSquidInk: #232F3E !default;

// Fonts
// -------------------------------------------------------------
    $awsuiMonospace: Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;

// Sidebar
// -------------------------------------------------------------
    $labsSidebarWidth: 21.88% !default;
    $labsSidebarMinWidth: 30.6rem !default;
    $labsSidebarMaxWidth: 40rem !default;
    $labsSidebarPadding: 2rem !default;
