@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

.Header {
    background-color: $awsuiGrey100;
    box-shadow: inset 0 1px 0 0 $awsuiGrey300, inset 0 -1px 0 0 $awsuiGrey300,
        0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 1rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @include breakpoint(xl) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 9rem;
        min-height: 13rem;
    }
}

.HeaderContent {
    text-align: center;

    .HeaderContent__title {
        text-align: center;
        font-weight: 400;
    }

    .HeaderContent__details {
        font-size: 10.5pt;
        line-height: 2rem;
    }

    @include breakpoint(xl) {
        text-align: left;
        .HeaderContent__title,
        .HeaderContent__details {
            display: inline;
        }
        .HeaderContent__details {
            padding-left: 1rem;
        }
    }
}

.ActionBar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;

    .session-button {
        margin-right: 0;
    }

    .console-button {
        flex: 0 0 100%;
        margin-left: 0;
        margin-top: 1rem;

        button {
            width: 100%;
            text-align: center;
        }
    }

    @include breakpoint(sm) {
        justify-content: center;

        .session-button {
            margin-left: 1rem;
        }
        .console-button {
            flex: 0 1 auto;
            margin-left: 1rem;
            margin-top: 0;
        }
    }

    @include breakpoint(xl) {
        margin-top: 0;
        flex: 0 0 490px;
        justify-content: flex-end;
    }
}
