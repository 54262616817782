.awsui-app-layout__notifications {
    position: sticky;
    top: 0;
    z-index: 900;
}

.awsui-app-layout__content {
    scroll-behavior: smooth;
}

[data-testid='jump-to-main'] {
    position: fixed;
    display: inline-block;
    padding: 0.5rem;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -3;

    &:focus-within, &:focus {
        z-index: 3;
    }
}